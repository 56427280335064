var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_vm._m(2),_c('div',[_c('div',{staticClass:"dom2relative"},[_vm._m(3),_c('div',{staticClass:"domUn2 domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(4)])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/暖点-l4sn6mmdoj0t.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("暖点SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v(" 该平台助力烘焙店实现采销线上/线下一体化、批发/零售一体化、为其一键搭建销售专属商城并提供数字化管理后台；且为其提供耗材采购专属商城，提供全品类耗材商品库，为烘焙行业降本增效，打造全场景全业务全渠道的数字化解决方案。 ")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})]),_c('div',{staticClass:"domUn domFexidTop"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/暖点切图-svai97wgfmgg.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup wrapper_1200"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domUn2Center"},[_c('div',{staticClass:"topBox"}),_c('div',{staticClass:"domUn2Box"},[_c('div',{staticClass:"img1 animated"},[_c('img',{staticStyle:{"width":"387px","height":"327px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/暖点切图xx1x-ij6shw7kz9pw.png","alt":""}})]),_c('div',{staticClass:"img2 animated"},[_c('img',{staticStyle:{"width":"937px","height":"975px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/暖点切图xx2x-i0labo4fop45.png","alt":""}})])]),_c('div',{staticClass:"tBtmBox"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})]),_c('div',{staticClass:"domUn domFexidTop"},[_c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')]),_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/暖点切图xx3x-pqsptpssegj3.png","alt":""}})])])])
}]

export { render, staticRenderFns }